import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormContentService } from './form-content/form-content.service';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  providers: [
    FormContentService
  ]
})
export class AppSharedModule { }
