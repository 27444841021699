<div class="layout-breadcrumb-container p-d-flex p-ai-center p-jc-between">
    <div class="layout-breadcrumb-left-items p-d-flex p-ai-center">
        <a [routerLink]="['#']" class="menu-button" (click)="appLayout.onMenuButtonClick($event)"
            *ngIf="appLayout.isStatic() && !appLayout.isMobile()">
            <i class="pi pi-bars"></i>
        </a>

        <p-breadcrumb [model]="items" styleClass="layout-breadcrumb p-py-2"></p-breadcrumb>
    </div>
    <div class="layout-breadcrumb-right-items p-d-flex">

    </div>
</div>