import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from './spinner.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
    public finished : boolean = true;
    @Input() message = '';

    constructor(public loader: LoaderService) { }

    ngOnInit() 
    {
        // document.body.style.overflow = 'hidden';
    }

    ngOnDestroy()
    {
        // document.body.style.overflow = 'auto';
    }
}