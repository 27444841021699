import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <div class="footer-logo-container align-items-center">
                <div>Powered by</div>
                <img [src]="'assets/layout/images/orisline-logo-white.png'" />
            </div>
            <div class="text-footer col-3-footer ">
                <p>Henry Schein One Italia S.r.l.</p>
                <p>
                    Via Giovanni Amendola, 7 20063, Cernusco sul Naviglio (MI)
                </p>
                <p>P. IVA: IT11654690152.</p>
                <p>
                    All rights reserved. Use of this website
                    <br />
                    signifies your agreement to the Terms of Use.
                </p>
            </div>
            <div class="text-footer col-3-footer ">
                <p>
                    <a
                        style="text-decoration:none; color:white"
                        href="https://orisline.com/it/contatti/"
                        >Contacts</a
                    >
                </p>
                <p>
                    <a
                        style="text-decoration:none; color:white"
                        href="https://orisline.com/it/privacy-policy/"
                        >Privacy Policy</a
                    >
                </p>
                <p>
                    <a
                        style="text-decoration:none; color:white"
                        href="https://orisline.com/it/cookie-policy/"
                        >Cookie Policy</a
                    >
                </p>
            </div>
            <div class="flex text-footer">
                <a
                    href="https://www.facebook.com/orislinesoftware/"
                    style="margin: 0 15px"
                    target="_blank"
                >
                    <img
                        src="assets/layout/images/icons/facebook.svg"
                        alt="Facebook"
                        class="social-icon-footer"
                    />
                </a>
                <a
                    href="https://www.youtube.com/channel/UCgrWt8GW7wayhNyxn9z98MQ"
                    style="margin: 0 15px"
                    target="_blank"
                >
                    <img
                        src="assets/layout/images/icons/youtube.svg"
                        alt="Youtube"
                        class="social-icon-footer"
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/orisline/"
                    style="margin: 0 15px"
                    target="_blank"
                >
                    <img
                        src="assets/layout/images/icons/linkedin.svg"
                        alt="Linkedin"
                        class="social-icon-footer"
                    />
                </a>
                <a
                    href="https://www.instagram.com/orisline_software/"
                    style="margin: 0 15px"
                    target="_blank"
                >
                    <img
                        src="assets/layout/images/icons/instagram.svg"
                        alt="Instagram"
                        class="social-icon-footer"
                    />
                </a>
            </div>
        </div>
    `,
})
export class AppFooterComponent {
    constructor() {}
}
