import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppLayoutComponent } from './app.layout.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    public model: any[];

    constructor(public app: AppComponent, public appLayout: AppLayoutComponent) { }

    ngOnInit() {
        this.model = [
            {
            },
        ];
    }
}
