import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private loading: boolean = false;

    constructor() { }

    setLoading(loading: boolean) {
        this.loading = loading;
        // if(loading)
        //     document.body.style.overflow = 'hidden';
        // else
        //     document.body.style.overflow = 'auto';
    }

    getLoading(): boolean {
        return this.loading;
    }
}