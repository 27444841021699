import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { AppLayoutComponent } from './app.layout.component';

@Component({
    selector: 'app-topbar',
    template: `
            <div class="layout-topbar">
            
                <div class="layout-topbar-left">
                    <!-- <div class="logo">
                        <img [src]="'assets/layout/images/orisline-logo.png'">
                    </div> -->
                </div>

                <!-- <app-menu></app-menu> -->

                <div class="layout-topbar-right">
                    
                    <ul class="layout-topbar-right-items">
                        
                        <p-dropdown class="p-dropdown-flag" [options]="items" (onChange)="onSelectedLangChange($event)" [(ngModel)]="selectedCountry" optionLabel="name" placeholder="">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center" *ngIf="selectedCountry">
                                    <img src="assets/demo/images/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" style="width: 18px"/>
                                    <div>{{ selectedCountry.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center">
                                    <img src="assets/demo/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px"/>
                                    <div>{{ country.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
  
                    </ul>
                </div>
            </div>
        
    `
})
export class AppTopbarComponent implements OnInit {
    items: any[];
    selectedCountry: any;
    constructor(public app: AppComponent, public appLayout: AppLayoutComponent, private translate: TranslateService) { }

    ngOnInit(): void {
        this.translate.addLangs(['it', 'en', 'es', 'pt']);
        this.translate.setDefaultLang('en');
        let browserLang = this.translate.getBrowserLang();
        browserLang = browserLang.match(/it|en|es|pt/) ? browserLang : 'en';

        this.items = [
            { name: ' ', code: 'it', locale: 'it' },
            { name: ' ', code: 'gb', locale: 'en' },
            { name: ' ', code: 'pt', locale: 'pt' },
            { name: ' ', code: 'es', locale: 'es' }
        ];

        this.selectedCountry = this.items.find(x => x.code == browserLang);
        this.translate.use(this.selectedCountry.locale);
    }

    onSelectedLangChange(event) {
        this.translate.use(event.value.locale);
    }
}